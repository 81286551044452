export const CONTEXT_RECORDS_TABS = {
  Requests: {
    name: 'Requests',
    key: 'requests',
  },
  Attributes: {
    name: 'Attributes',
    key: 'attributes',
  },
  Attachments: {
    name: 'Attachments',
    key: 'attachments',
  },
} as const;

export const CONTEXT_RECORDS_ACTION_TYPES = {
  archive: 'archive',
  delete: 'delete',
  makeActive: 'makeActive',
} as const;

export type ActionType =
  (typeof CONTEXT_RECORDS_ACTION_TYPES)[keyof typeof CONTEXT_RECORDS_ACTION_TYPES];

export { ContextRecordStatus as CONTEXT_RECORD_STATUS } from '@trustlayer/common';
