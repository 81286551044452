import styled from 'styled-components';

import { PageActions } from './components/PageActions';
import { SideBar } from './components/SideBar';

type DetailsPageLayoutProps = {
  children: React.ReactNode;
};

export const DetailsPageLayout = ({ children }: DetailsPageLayoutProps) => {
  return <StyledWrapper>{children}</StyledWrapper>;
};

const StyledWrapper = styled.div`
  --margin: 8px;

  display: flex;
  flex-direction: column;
  position: relative;
  margin: var(--margin);
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  box-shadow: ${({ theme }) => theme.shadows.pageCard};
  min-height: calc(100% - var(--margin) * 2);
  overflow: hidden;
`;

const StyledTopBar = styled.div`
  position: relative;
  display: flex;
  gap: 26px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  padding: 8px 24px;
  align-items: center;
`;

const StyledMainContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;
  flex-grow: 1;
`;

const StyledHeader = styled.section`
  --grid-t-area: 1 / 1 / 2 / 3;
  --grid-tr-area: 1 / 2 / 2 / 3;
  --grid-tl-area: 1 / 1 / 2 / 2;
  --grid-br-area: 2 / 2 / 3 / 3;
  --grid-bl-area: 2 / 1 / 3 / 2;
  --grid-b-area: 2 / 1 / 3 / 3;

  padding: 16px 24px;
  background-color: ${({ theme }) => theme.colors.white};
  display: grid;
  gap: 20px;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};

  .top-right-actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    grid-area: var(--grid-tr-area);
  }

  .top-full-actions {
    display: flex;
    justify-content: flex-start;
    grid-area: var(--grid-t-area);
  }

  .bottom-full-actions {
    display: flex;
    justify-content: flex-start;
    grid-area: var(--grid-b-area);
  }

  .bottom-left-actions {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    grid-area: var(--grid-bl-area);
  }

  .bottom-right-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    grid-area: var(--grid-br-area);
  }
`;

const StyledContent = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
`;

const StyledBody = styled.div`
  flex-grow: 1;
  position: relative;
  padding: 16px 24px;
`;

DetailsPageLayout.SideBar = SideBar;
DetailsPageLayout.PageActions = PageActions;
DetailsPageLayout.TopBar = StyledTopBar;
DetailsPageLayout.MainContent = StyledMainContent;
DetailsPageLayout.Header = StyledHeader;
DetailsPageLayout.Body = StyledBody;
DetailsPageLayout.Content = StyledContent;
