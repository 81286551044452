import type {
  GetRowIdFunc,
  GridApi,
  GridReadyEvent,
  IServerSideGroupSelectionState,
  PaginationChangedEvent,
  SelectionChangedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-quartz.css';
import './trustlayer-table-theme.css';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';

import {
  CustomHeaderWithSelection,
  type CustomHeaderWithSelectionRef,
} from './components/CustomHeaderWithSelection';
import type {
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  ColDef,
  CustomHeaderProps,
  FilterChangedEvent,
  FilterChangedParams,
  GetRowData,
  TableFilters,
  TableSortingState,
  TableState,
} from './types';

type SetFilterParam = TableFilters;

export type TableRef = {
  refreshTable: () => void;
  resetSelection: () => void;
  selectVisibleRows: () => void;
  selectAllRows: () => void;
  toggleFiltersPanel: () => void;
  resetFilters: () => void;
  getFilters: () => TableFilters | null;
  stopEditing: (param: boolean) => void;
  setFilters: (param: SetFilterParam) => void;
  getSortableColumns: () => { id: string; name: string }[];
  setSorting: (columnId: string, direction: 'asc' | 'desc') => void;
  undoEditing: () => void;
  editCell: ({
    rowIndex,
    columnField,
  }: {
    rowIndex: number;
    columnField: string;
  }) => void;
};

export type CustomSelectionChangedEvent = {
  isSelectAllChecked: boolean;
  selectedIds: any[];
  rowsTotalCount: number;
  selectedRowsData: any;
  visibleRowsCount: number;
};

type TableProps = {
  getRowData: GetRowData;
  onSelectionChanged?: (params: CustomSelectionChangedEvent) => void;
  onCellEditStopped?: (params: CellEditingStoppedEvent) => void;
  onCellEditStarted?: (params: CellEditingStartedEvent) => void;
  onFilterChanged?: (params: FilterChangedParams) => void;
  onSortChanged?: (params: TableSortingState) => void;
  onPaginationChanged?: (params: any) => void;
  isSideBarDisbaled?: boolean;
  isDoubleClickToEditDisabled?: boolean;
  /**
   * @note with no pagination the default limit will be 100 rows.
   */
  isPaginationDisabled?: boolean;
  tableState?: TableState;
  columnDefs: ColDef[];
};

const DEFAULT_PAGE_SIZE_LIST = [12, 24, 36, 48, 60];
const DEFAULT_PAGE_SIZE = 12;
const DEFAULT_COL_DEFS = {
  suppressMenu: true,
  sortable: false,
  minWidth: 130,
  flex: 1,
  suppressHeaderFilterButton: true,
  filterParams: {
    defaultToNothingSelected: true,
    suppressSelectAll: true,
    suppressMiniFilter: true,
    maxNumConditions: 1,
  },
};

/**
 * @note AgGrid API doesn't expose a method to select visible rows with the Server Side Row Model.
 * This is a workaround to select all visible rows.
 */
const handleSelectVisibleRows = (
  gridApi?: GridApi,
  excludedIds?: string[] | IServerSideGroupSelectionState[],
) => {
  if (!gridApi) {
    return undefined;
  }

  const renderedNodes = gridApi.getRenderedNodes();

  const renderedRowsIds = renderedNodes
    .map((node) => node.id)
    .filter((id) => id && !excludedIds?.includes(id || ''));

  gridApi.deselectAll();
  gridApi.setServerSideSelectionState({
    selectAll: false,
    toggledNodes: renderedRowsIds as string[],
  });
};

export const Table = forwardRef<TableRef, TableProps>(
  (
    {
      getRowData,
      columnDefs,
      onSelectionChanged,
      onFilterChanged,
      onSortChanged,
      onPaginationChanged,
      onCellEditStopped,
      onCellEditStarted,
      isSideBarDisbaled,
      isPaginationDisabled,
      isDoubleClickToEditDisabled = false,
      tableState = { filters: {}, sorting: {}, pagination: {} },
    },
    ref,
  ) => {
    const gridRef = useRef<AgGridReact>(null);
    const customHeaderWithSelectionRef =
      useRef<CustomHeaderWithSelectionRef>(null);
    const [pageSize, setPageSize] = useState(
      tableState.pagination?.pageSize || DEFAULT_PAGE_SIZE,
    );
    const isFirstGetRowsCompleted = useRef(false);

    /**
     * @note `autoHeight` could brake the grid when the pagination is disabled as we are using the SSRM.
     */
    const hasAutoHeight = !isPaginationDisabled;

    /**
     * @note replace default headerCheckboxSelection with custom one
     */
    const [updatedColumnDefs] = useState(
      columnDefs?.map((columnDef) => {
        if (columnDef.headerCheckboxSelection) {
          return {
            ...columnDef,
            // hide default headerCheckboxSelection component
            headerCheckboxSelection: undefined,
            // replace the default headerCheckboxSelection with a custom one
            headerComponent: (props: CustomHeaderProps) => (
              <CustomHeaderWithSelection
                ref={customHeaderWithSelectionRef}
                {...props}
              />
            ),
          };
        }
        return columnDef;
      }),
    );

    useImperativeHandle(
      ref,
      () => ({
        refreshTable: () => {
          gridRef?.current?.api?.refreshServerSide();
        },
        resetSelection: () => {
          customHeaderWithSelectionRef?.current?.resetState();
          gridRef?.current?.api.deselectAll();
        },
        selectVisibleRows: () => {
          handleSelectVisibleRows(gridRef?.current?.api);
        },
        selectAllRows: () => {
          gridRef?.current?.api.setServerSideSelectionState({
            selectAll: true,
            toggledNodes: [],
          });
        },
        toggleFiltersPanel: () => {
          const isFilterPanelOpen = gridRef?.current?.api.getOpenedToolPanel();
          if (!isFilterPanelOpen) {
            gridRef?.current?.api.openToolPanel('filters');
          } else {
            gridRef?.current?.api.closeToolPanel();
          }
        },
        resetFilters: () => {
          gridRef?.current?.api?.setFilterModel({});
        },
        getFilters: () => {
          return gridRef?.current?.api.getFilterModel() || null;
        },
        setFilters: (filters) => {
          gridRef?.current?.api?.setFilterModel(filters);
        },
        setSorting: (columnId, direction) => {
          gridRef?.current?.api?.applyColumnState({
            state: [
              {
                colId: columnId,
                sort: direction,
              },
            ],
            //? remove this if you want to have multiple columns sorting
            defaultState: { sort: null },
          });
        },
        getSortableColumns: () => {
          const columns = gridRef?.current?.api.getAllGridColumns();
          const visibleColumns = columns?.filter((column) =>
            column.isSortable(),
          );
          return (
            visibleColumns?.map((column) => ({
              id: column.getColId(),
              name: column.getColDef().headerName || '',
            })) || []
          );
        },
        editCell: ({ rowIndex, columnField }) => {
          gridRef?.current?.api.setFocusedCell(rowIndex, columnField);
          gridRef?.current?.api.startEditingCell({
            rowIndex,
            colKey: columnField,
          });
        },
        stopEditing: (param) => {
          gridRef?.current?.api.stopEditing(param);
        },
        undoEditing: () => {
          gridRef?.current?.api.undoCellEditing();
        },
      }),
      [],
    );

    /**
     * @note Ag Grid requires every prop should be a useMemo or a useCallback to perform properly
     */
    const autosizeConfig = useMemo(
      () => ({
        type: 'fitGridWidth' as const,
      }),
      [],
    );

    /**
     * @note Ag Grid requires every prop should be a useMemo or a useCallback to perform properly
     */
    const sideBarConfig = useMemo(() => {
      return isSideBarDisbaled
        ? undefined
        : {
            toolPanels: ['filters'],
          };
    }, [isSideBarDisbaled]);

    const handleOnGridReady = useCallback(
      (gridEvent: GridReadyEvent) => {
        gridRef?.current?.api.setFilterModel(tableState.filters || null);

        if (tableState.sorting?.columnId) {
          gridRef?.current?.api?.applyColumnState({
            state: [
              {
                colId: tableState.sorting?.columnId,
                sort: tableState.sorting?.direction,
              },
            ],
            //? remove this if you want to have multiple columns sorting
            defaultState: { sort: null },
          });
        }

        gridEvent.api.setGridOption('serverSideDatasource', {
          getRows: async (params) => {
            isFirstGetRowsCompleted.current = true;
            const {
              startRow = 0,
              endRow = DEFAULT_PAGE_SIZE,
              sortModel,
              filterModel,
            } = params.request;

            const rowData = await getRowData({
              startRow,
              endRow,
              filters: filterModel,
              sorts: sortModel,
            });

            params.success(rowData);
          },
        });
      },
      [getRowData, tableState],
    );

    const handlePaginationChanged = useCallback(
      (params: PaginationChangedEvent) => {
        const pageSize = params.api.paginationGetPageSize();
        const currentPage = params.api.paginationGetCurrentPage();

        if (params.newPageSize) {
          setPageSize(pageSize);
        }

        /*
        We know that handlePaginationChanged is automatically called also when the table first render.
        And in this case it always have currentPage = 0.
        If tableState contains instead a different currentPage, we need to force it into the ag grid state by:
          1. setting the row count to an infinite number otherwise the paginationGoToPage won't be applied by AGGrid, probably because if it doesn't know that the toalCount allows the desired it doesn't apply it
          2. setting the desiredpage
        The isFirstGetRowsCompleted is necessary because for an unknown reason this handlePaginationChanged is called multiple times, just for the first render, all with currentPage = 0,
        therefore we want to make sure that it keeps forcing the desired page until the first getRows (data fetching) occurs.

        In the `else` condition we manage instead the typical pagination changed
        */
        if (
          !isFirstGetRowsCompleted.current &&
          tableState.pagination?.currentPage &&
          tableState.pagination?.currentPage !== currentPage
        ) {
          params.api.setRowCount(999999);
          params.api.paginationGoToPage(tableState.pagination?.currentPage);
        } else {
          onPaginationChanged?.({
            pageSize,
            currentPage,
          });
        }
      },
      [onPaginationChanged, tableState.pagination?.currentPage],
    );

    const handleSelectionChanged = useCallback(
      (params: SelectionChangedEvent) => {
        // @ts-expect-error - selectAll is not defined in the types
        const { toggledNodes, selectAll } =
          params.api.getServerSideSelectionState() || {};

        /**
         * @note
         * (selectAll && toggledNodes?.length) - since our server doesn't support excluded ids, when the user selects all rows and then deselects some of them,
         * we should act as current page rows selected excluding the deselected ones.
         *
         * @note the statement exits from the function since "handleSelectVisibleRows" will trigger
         * the "onSelectionChanged" again with the updated params and state.
         */

        if (selectAll && toggledNodes?.length) {
          handleSelectVisibleRows(params.api, toggledNodes);
          return;
        }

        /**
         * @note we are aware that the "getSelectedRows" method is not suggested to use with the Server Side Row Model,
         * but it's the only way to get selected rows data.
         */
        // eslint-disable-next-line
        let selectedRowsData = [];

        if (!selectAll) {
          selectedRowsData = params.api.getSelectedRows();
        }

        const exportedSelectionState: CustomSelectionChangedEvent = {
          isSelectAllChecked: selectAll as boolean,
          selectedIds: !selectAll && toggledNodes ? toggledNodes : [],
          rowsTotalCount: params.api.paginationGetRowCount(),
          selectedRowsData,
          visibleRowsCount: params.api.getRenderedNodes()?.length,
        };

        if (onSelectionChanged) {
          onSelectionChanged(exportedSelectionState);
        }
      },
      [onSelectionChanged],
    );

    const handleFiltersChanged = useCallback(
      (params: FilterChangedEvent) => {
        const filterModel = params.api.getFilterModel();
        const activeFilterCount = Object.keys(filterModel || {}).length;

        if (onFilterChanged) {
          onFilterChanged({
            activeFiltersCount: activeFilterCount,
            activeFilters: filterModel,
          });
        }
      },
      [onFilterChanged],
    );

    const handleSortingChanged = useCallback(
      (params: SortChangedEvent) => {
        const appliedSorting = (params.api.getState()?.sort?.sortModel ||
          [])[0];

        const sortState = {
          columnId: appliedSorting.colId,
          direction: appliedSorting.sort || undefined,
        };

        onSortChanged?.(sortState);
      },
      [onSortChanged],
    );

    /**
     * @note it assings the value data.id as row identifier instesad of the default row index.
     * the `id` field should be passed as rowData to the getRows method in the serverSideDatasource.
     *
     * @see mapQueryDataToColumnsData src/modules/document/containers/DocumentsList/DocumentsList.utils.ts
     */
    const handleGetRowId: GetRowIdFunc = useCallback((params) => {
      return params.data._id;
    }, []);

    const onCellEditingStarted = useCallback(
      (params: CellEditingStartedEvent) => {
        onCellEditStarted?.(params);
      },
      [onCellEditStarted],
    );

    const onCellEditingStopped = useCallback(
      (params: CellEditingStoppedEvent) => {
        onCellEditStopped?.(params);
      },
      [onCellEditStopped],
    );

    return (
      <StyledAgGridWrapper
        className="ag-theme-quartz ag-theme-trustlayer"
        $explicitHeight={Boolean(!hasAutoHeight)}
      >
        <AgGridReact
          ref={gridRef}
          suppressClickEdit={isDoubleClickToEditDisabled}
          pagination={!isPaginationDisabled}
          suppressContextMenu
          defaultColDef={DEFAULT_COL_DEFS}
          sideBar={sideBarConfig}
          suppressRowClickSelection
          animateRows={false}
          domLayout={hasAutoHeight ? 'autoHeight' : undefined}
          rowModelType={'serverSide'}
          rowSelection={'multiple'}
          reactiveCustomComponents
          paginationPageSizeSelector={DEFAULT_PAGE_SIZE_LIST}
          paginationPageSize={pageSize}
          cacheBlockSize={pageSize}
          onGridReady={handleOnGridReady}
          onPaginationChanged={handlePaginationChanged}
          onSelectionChanged={handleSelectionChanged}
          onFilterChanged={handleFiltersChanged}
          onSortChanged={handleSortingChanged}
          columnDefs={updatedColumnDefs}
          getRowId={handleGetRowId}
          onCellEditingStarted={onCellEditingStarted}
          onCellEditingStopped={onCellEditingStopped}
          groupSelectsChildren={false}
          //? info - do not put this value in a constant, it will break the grid
          autoSizeStrategy={autosizeConfig}
        />
      </StyledAgGridWrapper>
    );
  },
);

const StyledAgGridWrapper = styled.div<{
  $explicitHeight: boolean;
}>`

  width: 100%;

  ${({ $explicitHeight }) =>
    $explicitHeight
      ? css`height: clamp(500px, 100%, 1500px);`
      : css`
      /* force an height range when "autoHeight" is eanbled to avoid white space */
      .ag-body-viewport {
        max-height: 700px;
        min-height: 250px;
        overflow-y: scroll;
      };
    `}

  .hiddenBlock {
    display: none;
  }
`;
