import { ResizeHandler } from '@common/components/ResizeHandler';
import { useResize } from '@common/hooks/useResize';
import { Layout } from 'antd';
import { useMemo } from 'react';
import styled from 'styled-components';

type SideBarProps = {
  children: React.ReactNode;
  collapsed: boolean;
  resizeStorageKey: string;
  onCollapse: (collapsed: boolean) => void;
};

export const SideBar = ({
  children,
  collapsed,
  onCollapse,
  resizeStorageKey,
}: SideBarProps) => {
  const minThresholdWatch = useMemo<[number, () => void]>(
    () => [210, () => onCollapse(true)],
    [onCollapse],
  );

  const { resizableRef, handlerRef } = useResize({
    storageKey: resizeStorageKey,
    maxWidth: 500,
    defaultWidth: 385,
    minWidth: 50,
    minThresholdWatch,
    enabled: !collapsed,
    placement: 'left',
    onClick: () => onCollapse(!collapsed),
  });

  return (
    <>
      <ResizeHandler
        ref={handlerRef}
        onToggle={() => onCollapse(!collapsed)}
        toggleDirection={collapsed ? 'left' : 'right'}
        toggleButtonTopOffset={38}
      />
      <StyledSiderResizer ref={resizableRef}>
        <StyledSider
          collapsed={collapsed}
          onCollapse={onCollapse}
          collapsible
          collapsedWidth={50}
          trigger={null}
          width="100%"
        >
          {children}
        </StyledSider>
      </StyledSiderResizer>
    </>
  );
};

const StyledSider = styled(Layout.Sider)`
  background-color: ${({ theme }) => theme.colors.white} !important;
  border-left: 1px solid ${({ theme }) => theme.colors.gray};
`;

const StyledSiderResizer = styled.div`
  background-color: #fafafa;
  border-right: 1px solid rgba(196, 214, 236, 0.5);
  position: relative;
  flex-shrink: 0;

  & > * {
    height: 100%;
  }
`;
